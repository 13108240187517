import React from 'react';
import './App.css';
import { Home } from './containers/main';

function App() {
  return (
    <div>
      <Home/>
    </div>
  )
};

export default App;
